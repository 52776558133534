import { RSAKey } from "./rsa"
import { Key, PrivateKey } from "sshpk"
import { modInv } from "bigint-crypto-utils"

function exportPython(key: RSAKey) {
    let s = ""
    let missing = []
    for (let k of Object.keys(key)) {
        let v = (key as any)[k]
        if (v === null) missing.push(k)
        else s += `${k} = ${v}\n`
    }
    if (missing.length)
        s += "# unknown: " + missing.join(', ')
    return s
}

function getParts(key: Partial<RSAKey>) {
    let res = []
    for (let k of Object.keys(key)) {
        let v = (key as any)[k] as bigint | null
        if (v) {
            let buffer = []
            while (v > 0n) {
                buffer.unshift(Number(v & 0xffn))
                v >>= 8n
            }
            res.push({ name: k, data: new Buffer(Uint8Array.from(buffer)) })
        }
    }
    return res
}

function mapPub(key: RSAKey, format: string) {
    let { n, e } = key
    if (n === null || e === null)
        return "requires n & e"
    let parts = getParts({ n, e })
    let pk = new Key({ type: 'rsa', parts })
    return pk.toString(format)
}

function mapPriv(key: RSAKey, format: string) {
    let { n, e, d, p, q } = key
    if (n === null || e === null || d === null || p === null || q === null)
        return "requires n & e & d & p & q"
    if (p < 3n || q < 3n)
        return "invalid pq"
    const parts = getParts({
        n, e, d, p, q,
        dmp1: d % (p - 1n),
        dmq1: d % (q - 1n),
        iqmp: modInv(p, q),
    } as any)
    let pk = new PrivateKey({ type: 'rsa', parts })
    return (pk as any).toString(format)
}

export const rsaKeyExporters = [
    { name: 'Python', exportKey: exportPython },
    { name: 'Public SSH', exportKey: (key: RSAKey) => mapPub(key, 'ssh') },
    { name: 'Public PEM', exportKey: (key: RSAKey) => mapPub(key, 'pem') },
    { name: 'Private PEM', exportKey: (key: RSAKey) => mapPriv(key, 'pem') },
    { name: 'Private PCKS8', exportKey: (key: RSAKey) => mapPriv(key, 'pkcs8') },
    { name: 'Private DNSSEC', exportKey: (key: RSAKey) => mapPriv(key, 'dnssec') },
]