import React, { useState, useEffect, useRef } from 'react';
import { AllesApp } from 'react-alles-app';
import { useUser, User } from './User';
import { List, ListItem, ListItemIcon, ListItemText, Typography, Grid, IconButton, makeStyles, Link, Button, Dialog, DialogTitle, DialogContent, Divider } from '@material-ui/core';
import { Dashboard as DashboardIcon, ExitToApp as ExitToAppIcon, Description as DescriptionIcon, Settings as SettingsIcon, EventNote as EventNoteIcon, VpnKey as VpnKeyIcon, Help as HelpIcon, ScreenShare as ScreenShareIcon } from '@material-ui/icons';
import { Link as RouterLink, useLocation, Switch, Route } from 'react-router-dom';
import { Login } from './Login/Login';
import * as H from 'history';
import { CtfSelector, useSelectedCtf } from './Pads/Pads';
import { General } from './Pads/General'
import { Pads } from './Pads/Pads';
import { TaskView } from './Pads/Task';
import { Settings } from './Settings/Settings';
import { CtfOverview } from './Pads/CtfOverview';
import { RsaTool } from './RsaTool/RsaTool';
import { Cyberchef } from './Cyberchef/Cyberchef';
import { StreamingTool } from './Streaming/StreamingTool';
import { RoomView } from './Streaming/Room';
import clsx, { ClassValue } from 'clsx';


export const useTitle = (title: string) => {
  useEffect(() => {
    document.title = title
  }, [title])
}

const sidebarItems = (location: H.Location) => [{
  text: "General",
  icon: <EventNoteIcon />,
  selected: location.pathname === '/',
  link: '/'
},
{
  divider: true
},
{
  text: "CTF Overview",
  icon: <DescriptionIcon />,
  selected: location.pathname === '/overview',
  link: '/overview'
},
{
  text: "Pads",
  icon: <DashboardIcon />,
  selected: location.pathname === '/pads',
  link: '/pads'
},
{
  divider: true
},
{
  text: "Streaming Tool",
  icon: <ScreenShareIcon />,
  selected: location.pathname === '/streaming',
  link: '/streaming'
},
{
  text: "RSA Tool",
  icon: <VpnKeyIcon />,
  selected: location.pathname === '/rsatool',
  link: '/rsatool'
},
{
  text: "Cyberchef",
  icon: <HelpIcon />,
  selected: location.pathname === '/cyberchef',
  link: '/cyberchef'
},
{
  text: "Settings",
  icon: <SettingsIcon />,
  selected: location.pathname === '/settings',
  link: '/settings'
}
]

const useStyles = makeStyles(theme => ({
  '@global': {
    '*::-webkit-scrollbar': {
      width: '10px',
      height: '10px',
      backgroundColor: theme.palette.background.default,
      borderRadius: '10px',
    },
    '*::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 8px rgba(0,0,0,0.00)',
      backgroundColor: theme.palette.background.paper,
      borderRadius: '10px',
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.secondary.main,
      borderRadius: '10px',
      backgroundClip: 'content-box',
      minHeight: '10%'
    },
    ':root': {
      scrollbarColor: `${theme.palette.secondary.main} ${theme.palette.background.paper}`,
      scrollbarWidth: "thin",
    },
  },
  topbarGrid: {
    width: '50%'
  },
  logoutButton: {
    color: theme.palette.primary.contrastText
  },
  selectCtfButton: {
    color: theme.palette.primary.contrastText,
    marginRight: 20
  },
}))

const ThemedScrollbar = () => {
  useStyles()
  return <></>
}

const LoggedIn = ({ user }: { user: User }) => {
  const [dialogOpen, setDialogOpen] = useState(false)
  const selectedCtf = useSelectedCtf()
  const classes = useStyles()
  const location = useLocation()
  const [sidebarItemsList, setSidebarItemsList] = useState(sidebarItems(location))

  const [dialogWidth, setDialogWidth] = useState(0)
  const hiddenTitleList = useRef(null)

  useEffect(() => {
    setDialogWidth((hiddenTitleList.current as any).children[0].children[0].clientWidth)
  }, [hiddenTitleList])

  useEffect(() => {
    setSidebarItemsList(sidebarItems(location))
  }, [location])

  const sidebar = (classes: { drawerIcon: any; drawerEntryName: ClassValue; drawerEntryNameClosed: any; }, open: boolean) => <>
    <List ref={hiddenTitleList} id="testing" style={{ position: "absolute", top: "0", margin: "auto", left: "-100vw", visibility: "hidden" }}>
      {sidebarItemsList.map((entry, i) =>
        entry.divider ? <Divider key={i}/> : (
          <ListItem button key={i}>
            <ListItemText
              className={clsx(classes.drawerEntryName)}
              primary={'\u00A0\u00A0' + entry.text!!}
            />
          </ListItem>
        )
      )}
    </List>


    <List >
      {sidebarItemsList.map((entry, i) =>
        entry.divider ? <Divider key={i}/> : (
          <ListItem button selected={entry.selected!!} component={RouterLink} to={entry.link!!} key={i}>
            <ListItemIcon className={classes.drawerIcon}>
              {entry.icon!!}
            </ListItemIcon>
            <ListItemText
              style={open ? {
                maxWidth: dialogWidth
              } : {}}
              className={clsx(classes.drawerEntryName, !open && classes.drawerEntryNameClosed)}
              primary={'\u00A0\u00A0' + entry.text!!}
            />
          </ListItem>
        )
      )}
    </List>

  </>


  const topbar = <Grid container className={classes.topbarGrid} spacing={1} alignItems="center" justify="flex-end" >
    <Grid item>
      <Button variant="outlined" className={classes.selectCtfButton} onClick={() => setDialogOpen(true)}>Choose CTF</Button>
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>Choose CTF</DialogTitle>
        <DialogContent>
          <CtfSelector />
        </DialogContent>
      </Dialog>
    </Grid>
    <Grid item>
      <Typography>Hello, {user.name}!</Typography>
    </Grid>
    <Grid item>
      <IconButton component={Link} href={"/auth/logout"}><ExitToAppIcon className={classes.logoutButton} /></IconButton>
    </Grid>
  </Grid>

  return (
    <AllesApp name={"CTF Tool" + (selectedCtf?.value?.name ? " - " + selectedCtf.value.name : "")} sidebar={sidebar} topbar={topbar}>
      <ThemedScrollbar></ThemedScrollbar>
      <Switch>
        <Route path="/" exact>
          <General />
        </Route>
        <Route path="/overview" exact>
          <CtfOverview />
        </Route>
        <Route path="/pads" exact>
          <Pads />
        </Route>
        <Route path="/streaming" exact>
          <StreamingTool />
        </Route>
        <Route path="/streaming/room/:id">
          <RoomView />
        </Route>
        <Route path="/rsatool" exact>
          <RsaTool />
        </Route>
        <Route path="/cyberchef" exact>
          <Cyberchef />
        </Route>
        <Route path="/settings" exact>
          <Settings />
        </Route>
        <Route path="/task/:id">
          <TaskView />
        </Route>
      </Switch>
    </AllesApp>
  )
}

const LogInPage = () => {
  return (
    <AllesApp sidebar={() => undefined} name={"CTF Tool - Login"}>
      <Login />
    </AllesApp>
  )
}

export const App = () => {
  const [user,] = useUser()
  return user ? <LoggedIn user={user} /> : <LogInPage />
}

