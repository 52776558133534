import React, { useState } from 'react'
import { Button, Dialog, DialogTitle, DialogContentText, DialogContent, DialogActions, IconButton } from '@material-ui/core'

interface ButtonProps {
    title?: string,
    text?: string,
    confirmText?: string,
    cancelText?: string,
    color?: 'inherit' | 'primary' | 'secondary' | 'default',
    variant?: 'outlined' | 'text' | 'contained',
    disabled?: boolean
    className?: string,
    style?: React.CSSProperties,
    onClick?: (e: React.MouseEvent) => void
}

export const ConfirmableButton: React.FC<ButtonProps> = ({ style, title, text, className, disabled, confirmText, cancelText, children, color, variant, onClick }) => {
    const [open, setOpen] = useState(false)

    const handleSetOpen = (open: boolean) => (e: React.MouseEvent) => {
        e.preventDefault()
        e.stopPropagation()
        setOpen(open)
    }

    const handleOkay = (e: React.MouseEvent) => {
        e.preventDefault()
        e.stopPropagation()
        if (onClick)
            onClick(e)
        setOpen(false)
    }

    return <>
        <Button className={className} style={style} disabled={disabled} onClick={handleSetOpen(true)} variant={variant} color={color}>{children}</Button>
        <Dialog open={open} onClose={() => setOpen(false)}>
            {title ? <DialogTitle>{title}</DialogTitle> : undefined}
            <DialogContent>
                {text ? <DialogContentText>{text}</DialogContentText> : undefined}
                <DialogActions>
                    <Button onClick={handleSetOpen(false)} color='primary'>{cancelText || 'Cancel'}</Button>
                    <Button onClick={handleOkay} color='primary'>{confirmText || 'Confirm'}</Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    </>
}


interface IconButtonProps {
    title?: string,
    text?: string,
    confirmText?: string,
    cancelText?: string,
    disabled?: boolean
    className?: string,
    style?: React.CSSProperties,
    onClick?: (e: React.MouseEvent) => void
}

export const ConfirmableIconButton: React.FC<IconButtonProps> = ({ title, style, text, className, children, disabled, confirmText, cancelText, onClick }) => {
    const [open, setOpen] = useState(false)

    const handleSetOpen = (open: boolean) => (e: React.MouseEvent) => {
        e.preventDefault()
        e.stopPropagation()
        setOpen(open)
    }

    const handleOkay = (e: React.MouseEvent) => {
        e.preventDefault()
        e.stopPropagation()
        if (onClick)
            onClick(e)
        setOpen(false)
    }

    return <>
        <IconButton className={className} style={style} disabled={disabled} onClick={handleSetOpen(true)}>{children}</IconButton>
        <Dialog open={open} onClose={() => setOpen(false)}>
            {title ? <DialogTitle>{title}</DialogTitle> : undefined}
            <DialogContent>
                {text ? <DialogContentText>{text}</DialogContentText> : undefined}
                <DialogActions>
                    <Button onClick={handleSetOpen(false)} color='primary'>{cancelText || 'Cancel'}</Button>
                    <Button onClick={handleOkay} color='primary'>{confirmText || 'Confirm'}</Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    </>
}