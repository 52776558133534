import React from 'react'
import { CodiPad } from './CodiPad'
import { PadsDB } from './Pads'
import { useValueByName, Loading } from 'react-alles-app'
import { useTitle } from '../App'

export const General = () => {
    const generalPad = useValueByName<string>(PadsDB, "general")

    useTitle("CTF Tool - General")

    return <>
        {generalPad ? <CodiPad padId={generalPad} mode={'view'} /> : <Loading />}
    </>
}