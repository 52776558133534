import React from 'react'
import { useServerSettings } from '../User'
import { makeStyles } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

interface Props {
    padId: string,
    mode?: 'view' | 'edit' | 'both'
}

const useStyles = makeStyles({
    iframe: {
        flexGrow: 1
    }
})

export const CodiPad = ({ padId, mode }: Props) => {
    const serverSettings = useServerSettings()
    const classes = useStyles()

    if (!serverSettings) return <Alert>ServerSettings missing</Alert>
    return <iframe
        title="Codi Pad"
        className={classes.iframe}
        src={serverSettings.codiUrl + '/' + padId + (mode ? '?' + mode : '')}
        width={"100%"}
        frameBorder={0}
    />
}