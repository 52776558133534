import React, { useState, FormEvent } from 'react'
import { makeStyles, Theme, createStyles, Paper, IconButton, InputBase, Container, Typography, Card, CardContent, CardActionArea, TextField, Fab, Chip } from '@material-ui/core'
import { KeyboardReturn as KeyboardReturnIcon, DeleteForever as DeleteForeverIcon, Add as AddIcon, Edit as EditIcon } from '@material-ui/icons'
import clsx from 'clsx';
import { useLocalStorage, useListById, useAlertBar, createDB, useMapByName, useListByName, wsBackend, Loading } from 'react-alles-app';
import { Link } from 'react-router-dom';
import { request } from '../Request';
import { Node } from 'react-alles-app'
import { useUser, useLocalSession } from '../User';
import { ConfirmableIconButton } from '../Components/ConfirmableButton';
import { Autocomplete } from '@material-ui/lab';
import { useTitle } from '../App';


export interface Ctf {
    name: string,
    generalId: string,
    isDefault: boolean,
    archived: boolean
}

export interface TaskGroup {
    name: string
}

export interface Task {
    name: string,
    padId: string,
    points: number,
    finished: boolean,
    started: boolean,
    assignees: string[]
}

export const PadsDB = createDB(wsBackend("/pads/db"))

export const useDefaultCtf: () => Node<Ctf> | undefined = () => {
    const defaultRoot = useMapByName<Ctf>(PadsDB, "defaultRoot", true)
    return defaultRoot['default']
}

export const useSelectedCtf: () => Node<Ctf> | null = () => {
    const defaultCtf = useDefaultCtf()
    const [localSession,] = useLocalSession()

    const locallySelectedCtf = localSession?.selectedCtf
    return (locallySelectedCtf === null || locallySelectedCtf === undefined)
        ? (defaultCtf || null)
        : locallySelectedCtf
}

export function WithSelectedCtf<P extends Object>(Component: React.ComponentType<P & { ctf: Node<Ctf> }>): React.FC<P> {
    const Hoc: React.FC<P> = (props: P) => {
        const ctf = useSelectedCtf()
        if (ctf === null || !ctf.value)
            return <Loading />
        return <Component ctf={ctf} {...props}></Component>
    }
    return Hoc
}

const useCtfSelectorStyles = makeStyles({
    ctfChooserBox: {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: 30,
        paddingBottom: 20
    },
    ctfRow: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%'
    }
})

export const CtfSelectorRow = ({ ctf }: { ctf: Node<Ctf> }) => {
    const classes = useCtfSelectorStyles()
    const val = ctf.value!!

    return <div className={classes.ctfRow}>
        <div>{val.name}</div>
        <div>
            {val.isDefault ? <Chip size='small' variant='outlined' label='Default' color='primary' /> : undefined}
            {val.archived ? <Chip size='small' variant='outlined' label='Archived' color='default' /> : undefined}
        </div>
    </div>
}

export const CtfSelector = () => {
    let ctfs = useListByName<Ctf>(PadsDB, "ctfs", true).filter(v => !!v.value).filter(v => !v.value?.archived)
    ctfs = ctfs.slice().reverse()


    const [localSession, setLocalSession] = useLocalSession()

    const classes = useCtfSelectorStyles()

    const handleChange = (_: React.ChangeEvent<{}>, ctf: Node<Ctf> | null) => {
        setLocalSession({ ...localSession, selectedCtf: ctf })
    }

    return <div className={classes.ctfChooserBox}>
        <Autocomplete
            options={ctfs.filter(i => i.value !== undefined)}
            value={localSession?.selectedCtf}
            onChange={handleChange}
            getOptionLabel={(ctf) => ctf.value!!.name}
            renderOption={(ctf) => <CtfSelectorRow ctf={ctf} />}
            style={{ width: '30em' }}
            renderInput={(params) => <TextField {...params} label="CTF" variant="outlined" />}
            autoComplete
        />
    </div>
}




const useCreateGroupFormStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: '2px 4px',
            display: 'flex',
            alignItems: 'center',
            width: 400,
        },
        input: {
            marginLeft: theme.spacing(1),
            flex: 1,
        },
        iconButton: {
            padding: 10,
        },
        divider: {
            height: 28,
            margin: 4,
        },
    }),
);

const CreateGroupForm = ({ ctf }: { ctf: string }) => {
    const classes = useCreateGroupFormStyles()
    const [name, setName] = useState("")
    const showAlert = useAlertBar()


    const submit = (e: FormEvent) => {
        e.preventDefault()

        request<any>("/pads/group", { 'name': name, 'ctf': ctf }, 'PUT', showAlert)
            .then(result => {
                if (result.success) {
                    setName("")
                }
            })

        return false
    }

    return <Paper component="form" className={classes.root} onSubmit={submit}>
        <InputBase
            value={name}
            onChange={(e) => setName(e.target.value)}
            className={classes.input}
            placeholder="Create Group"
        />
        <IconButton type="submit" className={classes.iconButton}>
            <KeyboardReturnIcon />
        </IconButton>
    </Paper>
}


const useTaskStyles = makeStyles(theme => ({
    root: {
        minWidth: "17em",
        marginRight: 10,
        marginBottom: 10,
    },
    finishedTask: {
        backgroundColor: theme.palette.success.light,
        color: theme.palette.getContrastText(theme.palette.success.light)
    },
    newTask: {
        backgroundColor: theme.palette.error.light,
        color: theme.palette.getContrastText(theme.palette.error.light)
    },
    inProgressTask: {
        backgroundColor: theme.palette.info.light,
        color: theme.palette.getContrastText(theme.palette.info.light)
    },
    selfAssignedTask: {
        backgroundColor: theme.palette.info.dark,
        color: theme.palette.getContrastText(theme.palette.info.dark)
    },
    abandonedTask: {
        backgroundColor: theme.palette.warning.light,
        color: theme.palette.getContrastText(theme.palette.warning.light)
    },
    hoveringIcon: {
        right: 0,
        top: 0,
        margin: "auto!important",
        position: "absolute"
    }
}));

const useNewTaskStyles = makeStyles(() => ({
    root: {
        width: "17em",
        marginRight: 10,
        marginBottom: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    newTaskContent: {
        width: '100%',
        paddingBottom: "0 !important",
        paddingTop: "0 !important",
        marginTop: 10,
        marginBottom: 10
    },
    hoveringIcon: {
        right: 0,
        top: 0,
        margin: "auto!important",
        position: "absolute"
    }
}));

const NewTaskCard = ({ group }: { group: string }) => {
    const classes = useNewTaskStyles()
    const [text, setText] = useState("")

    const showAlert = useAlertBar()

    const submit = (e: FormEvent) => {
        e.preventDefault()
        const split = text.split("#")
        const name = split[0]
        const points = split.length > 1 ? split[1] : '1'

        request<any>("/pads/task", { 'name': name, 'group': group, 'points': points }, 'PUT', showAlert)
            .then(result => {
                if (result.success) {
                    setText("")
                }
            })

        return false
    }

    return <Card className={clsx(classes.root)} variant="outlined">
        <CardContent className={classes.newTaskContent} component='form' onSubmit={submit}>
            <IconButton className={classes.hoveringIcon}><AddIcon color="inherit" fontSize="small" /></IconButton>
            <TextField size="small" label="Task Name#Points" variant="outlined" value={text} onChange={(e) => setText(e.target.value)} />
        </CardContent>
    </Card>
}

const TaskCard = ({ id, task, editable }: { id: string, task: Task, editable: boolean }) => {
    const classes = useTaskStyles()
    const [user] = useUser()

    const deleteTask = (e: React.MouseEvent) => {
        e.preventDefault()
        request("/pads/task", { id: id }, "DELETE")
    }

    const name = user?.name

    const stateClass = task.finished ? classes.finishedTask : (
        task.assignees.length > 0 ? (
            name && task.assignees.includes(name) ? classes.selfAssignedTask : classes.inProgressTask
        ) : (
                task.started ? classes.abandonedTask : classes.newTask
            )
    )

    return <Card className={clsx(editable ? "wiggle" : undefined, classes.root, stateClass)} style={{ animationDelay: -Math.random() + "s" }} variant="outlined">
        <CardActionArea component={Link} to={"/task/" + id}>
            <CardContent>
                <ConfirmableIconButton title='Are you sure you want to delete this Task?' text='This can not be undone!' className={clsx(editable ? classes.hoveringIcon : undefined)} onClick={deleteTask} style={{ display: editable ? undefined : 'none' }}>
                    <DeleteForeverIcon color="inherit" fontSize="small" />
                </ConfirmableIconButton>
                <Typography variant="h6" component="h2">
                    <strong>{task.name}</strong>
                </Typography>
                <Typography component="p">
                    {task.assignees.length === 0 ? <>&nbsp;</> : task.assignees.join(", ")}
                </Typography>
                <Typography variant="body2" component="p">
                    <strong>{task.points}</strong> points
                </Typography>
            </CardContent>
        </CardActionArea>
    </Card>
}



const useStyles = makeStyles({
    outer: {
        width: "100%",
        flexGrow: 1,
        paddingTop: 20,
        display: 'flex',
        flexDirection: 'column'
    },
    groups: {
        width: "100%",

        flexGrow: 1,
        paddingTop: '1.5em',
    },
    group: {
        width: "100%",
        paddingBottom: 20,
    },
    tasks: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap'
    },
    topbar: {
        display: 'flex',
        justifyContent: 'space-between'
    }
})


const PadGroup = ({ editable, id, value }: { editable: boolean, id: string, value: TaskGroup }) => {
    const classes = useStyles()
    let tasks = useListById<Task>(PadsDB, id, true)
    const [user] = useUser()

    const deleteGroup = (e: React.MouseEvent) => {
        e.preventDefault()
        request("/pads/group", { id: id }, "DELETE")
    }

    const compareKey = (t: Node<Task>) => {
        if (!t.value) return -1
        else if (!t.value.started && !t.value.finished) return 0
        else if (t.value.started && t.value.assignees.length === 0) return 1
        else if (user?.name && t.value.assignees.includes(user.name) && !t.value.finished) return 2
        else if (t.value.started && !t.value.finished) return 3
        else return 4
    }
    tasks = tasks.sort((a, b) => compareKey(a) - compareKey(b))

    return <div className={classes.group}>
        <Typography variant="h4">{value.name}<ConfirmableIconButton title='Are you sure you want to delete this Group?' text='This can not be undone!' onClick={deleteGroup} style={{ visibility: editable ? undefined : 'hidden' }}>
            <DeleteForeverIcon color="disabled" />
        </ConfirmableIconButton>
        </Typography>
        <div className={classes.tasks}>
            <NewTaskCard key={"newTaskCard-" + id} group={id} />
            {tasks.map(node =>
                node.value ? <TaskCard id={node.valueId} task={node.value} key={node.valueId} editable={editable} /> : undefined
            )}
        </div>
    </div>
}


export const Pads = WithSelectedCtf(({ ctf }) => {
    const classes = useStyles()
    const [editable, setEditable] = useLocalStorage("padsEditMode", false)

    const groups = useListById<TaskGroup>(PadsDB, ctf.valueId, true)

    useTitle("CTF Tool - Pads")


    return <Container maxWidth={false} className={classes.outer} >
        <div className={classes.topbar}>
            <CreateGroupForm ctf={ctf.valueId} />
            <Fab color={editable ? "secondary" : undefined} onClick={() => setEditable(!editable)}>
                <EditIcon />
            </Fab>
        </div>
        <div className={classes.groups}>
            {groups.map(node =>
                node.value ? <PadGroup editable={editable} id={node.valueId} value={node.value} key={node.valueId} /> : undefined
            )}
        </div>
    </Container>
})