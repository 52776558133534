import React, { useState, FormEvent, useRef, useEffect } from 'react'
import { Container, Typography, Paper, makeStyles, TextField, Divider, FormGroup, FormControlLabel, Switch, Button, InputBase, IconButton, createStyles, Theme, Chip, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Link } from '@material-ui/core'
import { Autocomplete, Alert } from '@material-ui/lab'
import { useListByName, useAlertBar, createDB, useValueByName, wsBackend, Loading } from 'react-alles-app'
import { Ctf, PadsDB, CtfSelectorRow } from '../Pads/Pads'
import { Node } from 'react-alles-app'
import { useLocalSession } from '../User'
import { Add as AddIcon, FileCopy as FileCopyIcon, DeleteForever as DeleteForeverIcon, GitHub as GitHubIcon } from '@material-ui/icons'
import { request } from '../Request';
import { ConfirmableButton, ConfirmableIconButton } from '../Components/ConfirmableButton'
import { useTitle } from '../App'

const useStyles = makeStyles({
    divider: {
        marginTop: "2em",
        marginBottom: "2em"
    },
    ctfSelector: {
        marginTop: "2em",
        marginBottom: "1em"
    },
    ctfInputs: {
        marginRight: "1em"
    },
    dummyButton: {
        visibility: 'hidden'
    },
    copyTextArea: {
        opacity: 0,
        width: 1,
        height: 1
    }
})

const useCreateCtfFormStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: '2px 4px',
            display: 'flex',
            alignItems: 'center',
            width: '25em',
        },
        input: {
            marginLeft: theme.spacing(1),
            flex: 1,
        },
        iconButton: {
            padding: 10,
        },
        divider: {
            height: 28,
            margin: 4,
        },
    }),
);

const CreateCtfForm = ({ setCtf }: { setCtf: (ctf: Node<Ctf> | null) => void }) => {
    const classes = useCreateCtfFormStyles()
    const [name, setName] = useState("")
    const showAlert = useAlertBar()

    const submit = (e: FormEvent) => {
        e.preventDefault()
        request<any>("/pads/ctf", { 'name': name }, 'PUT', showAlert)
            .then((result: any) => {
                if (result.valueId) {
                    setName("")
                    setCtf(result)
                }
            })
        return false
    }

    return <Paper component="form" className={classes.root} onSubmit={submit}>
        <InputBase
            value={name}
            onChange={(e) => setName(e.target.value)}
            className={classes.input}
            placeholder="Create CTF"
        />
        <IconButton type="submit" className={classes.iconButton}>
            <AddIcon />
        </IconButton>
    </Paper>
}

const CTFSettings = () => {
    let ctfs = useListByName<Ctf>(PadsDB, "ctfs", true)
    ctfs = ctfs.slice().reverse()
    const [selectedCtf, setSelectedCtf] = useState<Node<Ctf> | null>(null)
    const [anticipatedCtf, setAnticipatedCtf] = useState<Node<Ctf> | null>(null)
    const classes = useStyles()
    const [localSession, setLocalSession] = useLocalSession()
    const [importing, setImporting] = useState(false)

    const showAlert = useAlertBar()

    useEffect(() => {
        const anticipatedValue = ctfs.find(v => anticipatedCtf?.valueId === v.valueId)
        if (anticipatedCtf && anticipatedValue) {
            console.log(anticipatedCtf)
            setAnticipatedCtf(null)
            setSelectedCtf(anticipatedValue)
        }
    }, [anticipatedCtf, ctfs])

    const ctfExists = selectedCtf?.value && ctfs.find(v => selectedCtf.valueId === v.valueId)

    const handleCtfChange = (_: React.ChangeEvent<{}>, ctf: Node<Ctf> | null) => {
        setSelectedCtf(ctf)
    }
    const handleDefaultClick = (ctf: Node<Ctf>) => () => {
        request<any>("/pads/ctf/default", { 'id': ctf.valueId }, 'POST', showAlert)
    }
    const handleSelectClick = (ctf: Node<Ctf>) => () => {
        setLocalSession({ ...localSession, selectedCtf: ctf })
    }
    const handleArchivedChange = (ctf: Node<Ctf>) => () => {
        request<any>("/pads/ctf/archived", { 'id': ctf.valueId }, 'POST', showAlert)
    }
    const handleDelete = (ctf: Node<Ctf>) => () => {
        request<any>("/pads/ctf", { 'id': ctf.valueId }, 'DELETE', showAlert)
    }
    const handleFileUpload = (e: any) => {
        if (e.target.files.length > 0 && !importing) {
            setImporting(true)
            request<any>("/pads/import", { 'ctfs': e.target.files.item(0)!! }, 'POST', showAlert)
                .then(a => {
                    if (a.ctfs) {
                        showAlert((close: () => void) => (
                            <Alert onClose={close} severity="success">
                                Imported {a.ctfs} CTFs from zip
                            </Alert>
                        ))
                    }
                    setImporting(false)
                })
        }
    }


    return <>
        <Typography variant="h5" gutterBottom>CTF Settings</Typography>
        <div style={{ marginBottom: '1em' }}>
            <input
                accept="application/zip"
                style={{ display: 'none' }}
                id="import-zip-upload"
                onChange={handleFileUpload}
                type="file"
            />
            <label htmlFor="import-zip-upload">
                <Button variant="contained" color="secondary" component="span" style={{ marginRight: '0.5em' }}>
                    {!importing ? 'Import' : <Loading />}
                </Button>
            </label>
            <Button variant="contained" color="primary" href="/pads/export">Export</Button>
        </div>
        <CreateCtfForm setCtf={setAnticipatedCtf} />
        <Autocomplete
            className={classes.ctfSelector}
            options={ctfs.filter(i => i.value !== undefined)}
            value={ctfExists ? selectedCtf : null}
            onChange={handleCtfChange}
            getOptionLabel={(ctf) => ctf.value!!.name}
            renderOption={(ctf) => <CtfSelectorRow ctf={ctf} />}
            style={{ width: '30em' }}
            renderInput={(params) => <TextField {...params} label="CTF" variant="outlined" />}
            autoComplete
        />

        {selectedCtf?.value && ctfExists ?
            <FormGroup row>
                <Button className={classes.ctfInputs} color='primary' variant='outlined' disabled={selectedCtf.value.isDefault} onClick={handleDefaultClick(selectedCtf)}>
                    Set as Default
                </Button>
                <Button className={classes.ctfInputs} color='primary' variant='outlined' disabled={selectedCtf.value.name === localSession.selectedCtf?.value?.name} onClick={handleSelectClick(selectedCtf)}>
                    Select
                </Button>
                <ConfirmableButton
                    className={classes.ctfInputs}
                    color='primary'
                    variant='outlined'
                    disabled={selectedCtf.value.isDefault}
                    onClick={handleDelete(selectedCtf)}
                    title='Are you sure you want to delete this CTF?'
                    text='This can not be undone'
                >
                    Delete
                </ConfirmableButton>
                <FormControlLabel
                    className={classes.ctfInputs}
                    control={<Switch color="secondary" checked={selectedCtf.value?.archived} onChange={handleArchivedChange(selectedCtf)} />}
                    label="Archived"
                />
            </FormGroup>
            : <FormGroup className={classes.dummyButton} row>
                <Button className={classes.ctfInputs} >Dummy Button</Button>
                <FormControlLabel
                    className={classes.ctfInputs}
                    control={<Switch />}
                    label="Dummy Switch"
                />
            </FormGroup>
        }
    </>
}


const KeysDB = createDB(wsBackend("/keys/db"))

const useImportKeysFormStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: '2px 4px',
            display: 'flex',
            alignItems: 'center',
            width: '25em',
        },
        input: {
            marginLeft: theme.spacing(1),
            flex: 1,
        },
        iconButton: {
            padding: 10,
        },
        divider: {
            height: 28,
            margin: 4,
        },
    }),
);

const ImportKeysForm = () => {
    const classes = useImportKeysFormStyles()
    const [name, setName] = useState("")
    const showAlert = useAlertBar()

    const submit = (e: FormEvent) => {
        e.preventDefault()
        request<any>("/keys/github", { 'github-name': name }, 'POST', showAlert)
            .then(result => {
                if (result.keys) {
                    showAlert((close: () => void) => (
                        <Alert onClose={close} severity="success">
                            Imported {result.keys} keys from GitHub
                        </Alert>
                    ))
                }
            })
        return false
    }

    return <Paper component="form" className={classes.root} onSubmit={submit}>
        <InputBase
            value={name}
            onChange={(e) => setName(e.target.value)}
            className={classes.input}
            placeholder="Import from GitHub"
        />
        <IconButton type="submit" className={classes.iconButton}>
            <GitHubIcon />
        </IconButton>
    </Paper>
}


const useKeySettingsStyles = makeStyles({
    container: {
        maxHeight: '30rem'
    },
    root: {
        marginTop: '1.5em',
        width: '100%'
    },
    textfield: {
        width: '100%'
    },
    keyPre: {
        whiteSpace: 'pre-wrap',
        wordBreak: 'break-all'
    }
})

interface Key {
    name: string,
    key: string
}

const KeySettings = () => {
    let keys = useListByName<Key>(KeysDB, "keyRoot", true)
    keys = keys.slice().reverse()

    const [nameField, setNameField] = useState("")
    const [keyField, setKeyField] = useState("")

    const classes = useKeySettingsStyles()
    const showAlert = useAlertBar()

    const submit = (e: FormEvent) => {
        e.preventDefault()

        request<any>("/keys/key", { 'name': nameField, 'key': keyField }, 'POST', showAlert)
            .then(result => {
                if (result.id) {
                    setNameField("")
                    setKeyField("")
                }
            })

        return false
    }

    const deleteKey = (id: string) => () => {
        request<any>("/keys/key", { 'key': id }, 'DELETE', showAlert)
    }

    return <>
        <Typography variant="h5" gutterBottom>SSH Keys</Typography>
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <ImportKeysForm />
            <Link style={{ paddingLeft: "1.5em" }} href="/keys">Keys File</Link>
        </div>
        <Paper className={classes.root}>
            <TableContainer className={classes.container}>
                <Table size="small" stickyHeader>
                    <TableHead>
                        <TableRow >
                            <TableCell align='left' style={{ width: '22em' }}>
                                <form onSubmit={submit}>
                                    <TextField className={classes.textfield} size="small" label="Name (optional)" variant="outlined" value={nameField} onChange={(e) => setNameField(e.target.value)} />
                                </form>
                            </TableCell>
                            <TableCell align='left'>
                                <form onSubmit={submit}>
                                    <TextField className={classes.textfield} size="small" label="Key" variant="outlined" value={keyField} onChange={(e) => setKeyField(e.target.value)} />
                                </form>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {keys.map((key) => {
                            return key.value ? (
                                <TableRow key={key.value.key}>
                                    <TableCell align='left'>{key.value.name}<ConfirmableIconButton title='Are you sure you want to delete this Key?' text='This can not be undone!' onClick={deleteKey(key.valueId)}>
                                        <DeleteForeverIcon color="disabled" />
                                    </ConfirmableIconButton></TableCell>
                                    <TableCell align='left'><pre className={classes.keyPre}>{key.value.key}</pre></TableCell>
                                </TableRow>
                            ) : undefined
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    </>
}


const AuthKeyDB = createDB(wsBackend("/auth/key"))

const AuthSettings = () => {
    const authKey = useValueByName<string>(AuthKeyDB, "authKey")
    const [showAuthKey, setShowAuthKey] = useState(false)
    const showAlert = useAlertBar()
    const textAreaRef = useRef<HTMLTextAreaElement>(null);

    const classes = useStyles()

    const handleAuthKeyClick = () => {
        setShowAuthKey(!showAuthKey)
    }

    const handleCopyClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault()
        e.stopPropagation()
        textAreaRef.current?.select()
        console.log(textAreaRef.current)
        document.execCommand('copy');
        showAlert((close: () => void) => (
            <Alert onClose={close} severity="success">
                Copied Authkey to clipboard
            </Alert>
        ))
    }

    const handleRegenerateClick = () => {
        request<any>("/auth/regenerate", {}, 'POST', showAlert)
    }

    return <>
        <Typography variant="h5" gutterBottom>Authentication Settings</Typography>
        {authKey ?
            (<>
                <ConfirmableButton
                    onClick={handleRegenerateClick}
                    variant='outlined'
                    title='Are you sure you want to regenerate the Auth Key?'
                    text='This might cause a great amount of pain and suffering, as everyone has to save a new Auth Key to their password managers'
                    className={classes.ctfInputs}
                >Regenerate</ConfirmableButton>
                <Chip
                    label={showAuthKey ? authKey : "Show Auth Key"}
                    icon={<IconButton onClick={handleCopyClick}><FileCopyIcon fontSize="small" /></IconButton>}
                    onClick={handleAuthKeyClick}
                />
            </>)
            : <Loading width={null} height={null} />
        }
        <form className={classes.copyTextArea}>
            <textarea value={authKey ? authKey : ""} ref={textAreaRef} />
        </form>
    </>
}

export const Settings = () => {
    const classes = useStyles()

    useTitle("CTF Tool - Settings")


    return <Container maxWidth='lg' style={{ marginTop: 30, marginBottom: 30 }}>
        <CTFSettings />
        <Divider className={classes.divider} />
        <AuthSettings />
        <Divider className={classes.divider} />
        <KeySettings />
    </Container >
}