import React from 'react'
import { Alert, AlertTitle } from '@material-ui/lab';

export async function request<T>(url: string, data: { [name: string]: string | Blob }, method: string = "get", showAlert: (alert: React.ReactNode) => void = () => { }): Promise<T | undefined> {
    const formData = new FormData();
    for (const key of Object.keys(data))
        formData.append(key, data[key])
    return await fetch((process.env.REACT_APP_BACKEND ? process.env.REACT_APP_BACKEND : "") + url, {
        method: method,
        headers: {
            'Accept': 'application/json'
        },
        body: formData,
        credentials: 'include'
    }).then(response => response.json())
        .then(result => {
            if (result.status && result.status >= 400) {
                showAlert((close: () => void) => (
                    <Alert onClose={close} severity="error">
                        <AlertTitle>Request to {url} failed!</AlertTitle>
                        <strong>{result.title}</strong>{result.details.length > 0 ? ": " + JSON.stringify(result.details) : undefined}
                    </Alert>
                ))
                return undefined
            }
            if (result.error) {
                showAlert((close: () => void) => (
                    <Alert onClose={close} severity="error">
                        {result.error}
                    </Alert>
                ))
                return undefined
            }
            if (result.warning) {
                showAlert((close: () => void) => (
                    <Alert onClose={close} severity="warning">
                        {result.warning}
                    </Alert>
                ))
            }
            if (result.success && result.success.message) {
                showAlert((close: () => void) => (
                    <Alert onClose={close} severity="success">
                        {result.success.message}
                    </Alert>
                ))
            }
            return result
        })
}