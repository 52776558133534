import React, { VideoHTMLAttributes, useRef, useEffect, useState, createRef } from 'react'
import { useParams, useHistory } from 'react-router-dom';
import { Typography, makeStyles, Fab, Card, CardContent, IconButton, Paper, Chip, Dialog } from '@material-ui/core';
import { RoomsDB, DBRoom, DBUser, DBStream } from './StreamingTool';
import { useValueById, useAlertBar, useListById, Node, useMapById } from 'react-alles-app';
import { useServerSettings } from '../User'
import { Client, LocalStream, RemoteStream } from 'ion-sdk-js';
import { IonSFUJSONRPCSignal } from 'ion-sdk-js/lib/signal/json-rpc-impl';
import { Alert } from '@material-ui/lab'
import ScreenShareIcon from '@material-ui/icons/ScreenShare';
import { request } from '../Request';
import { useUser } from '../User';
import { Fullscreen as FullscreenIcon, PictureInPicture as PictureInPictureIcon, WebAsset as WebAssetIcon } from '@material-ui/icons'
import { useTheme } from "@material-ui/core/styles";
import StopScreenShareIcon from '@material-ui/icons/StopScreenShare';
import { useInterval } from '../useInterval';


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        margin: 10,
        overflowX: "hidden",
        position: "relative",
        height: "100%",
        alignItems: "baseline",
        flexFlow: "column",
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    video: {
        width: "100%",
        height: "auto",
        borderRadius: "4px",
    },
    previews: {
        margin: theme.spacing(1),
        position: "relative",
        "&:hover video:not(:fullscreen)": {
            filter: "blur(3px)",
        },
        "&:hover div": {
            visibility: "visible"
        },
        display: "inline-flex",
        width: "350px",
        minWidth: "350px",
        maxWidth: "350px",
        boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
        borderRadius: "4px",
    },
    ownPreviews: {
        margin: theme.spacing(1),
        position: "relative",
        "&:hover video:not(:fullscreen)": {
            filter: "blur(3px)",
        },
        "&:hover div": {
            visibility: "visible"
        },
        display: "inline-block",
        width: "200px",
        minWidth: "200px",
        maxWidth: "200px",
    },
    noStreams: {
        margin: theme.spacing(1),
        position: "relative",
    },
    streamer: {
        position: "absolute",
        bottom: "0px",
    },
    ownStreams: {
        position: "relative",
        display: "flex",
        flexWrap: "nowrap",
        overflowX: "auto",
    },

    ownStreamsContainer: {
        position: "fixed",
        float: "left",
        bottom: "0",
        right: theme.spacing(1),
        //left: theme.spacing(1),
        //width: "100%",
        overflow: "hidden",
    },
    streamControls: {
        position: "absolute",
        visibility: "hidden",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
    },
    remoteUsers: {
        //height: "100%",
        display: "flex",
        flexFlow: "row wrap",
        width: "100%",
        justifyContent: "center",
        marginTop: theme.spacing(0.5),
    },
    memberChip: {
        position: "absolute",
        left: "0",
        bottom: "0",
        zIndex: 100,
        padding: theme.spacing(0.5),
        margin: theme.spacing(1.5),
    },
    userCard: {
        position: "relative",
        display: "flex",
        alignItems: "center",
        margin: theme.spacing(0.5),
        flexWrap: "wrap"
    },
    users: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
    },
    userChip: {
        zIndex: 100,
        padding: theme.spacing(0.5),
        margin: theme.spacing(1.5),
    },
}))

type PropsType = VideoHTMLAttributes<HTMLVideoElement> & {
    srcObject: MediaStream
}

type StreamType = {
    stream: MediaStream,
    memberId: string,
}

export default function Video({ srcObject, ...props }: PropsType) {
    const refVideo = useRef<HTMLVideoElement>(null)

    useEffect(() => {
        if (!refVideo.current) return
        refVideo.current.srcObject = srcObject
    }, [srcObject])

    return <video ref={refVideo} {...props} />
}

const makeFullscreen = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    let video = event.currentTarget.parentElement?.parentElement?.getElementsByTagName('video')[0] as HTMLVideoElement
    video.requestFullscreen()
}




const LocalStreamView = ({ stream, memberId, ...props }: StreamType) => {
    const classes = useStyles()
    const showAlert = useAlertBar()

    const makeScreenshot = (video: HTMLVideoElement) => {
        const canvas = document.createElement("canvas") as HTMLCanvasElement

        const h = (video.srcObject as MediaStream).getVideoTracks()[0].getSettings().height!!
        const w = (video.srcObject as MediaStream).getVideoTracks()[0].getSettings().width!!
        const wh = (w / h)!!
        const cw = w < h ? 216 * wh : 384
        const ch = w < h ? 216 : 384 * (1 / wh)

        canvas.setAttribute("width", String(cw))
        canvas.setAttribute("height", String(ch))
        const context = canvas.getContext("2d")

        if (context != null) {
            context.drawImage(video, 0, 0, cw, ch)

            let image = canvas.toDataURL("image/png")
            request<any>("/rooms/thumbnail", { 'memberId': memberId, 'streamId': (video.srcObject as any).id, 'thumbnail': image }, 'PUT', showAlert)

            //console.log(canvas.toDataURL("image/png"))     
        }
    }

    const startScrenshotting = (event: React.SyntheticEvent<HTMLVideoElement, Event>) => {
        let video = event.currentTarget.parentElement?.parentElement?.getElementsByTagName('video')[0] as HTMLVideoElement

        const interval = setInterval(() => {
            if (video.srcObject && (video.srcObject as MediaStream).active) {
                makeScreenshot(video)
            } else {
                clearInterval(interval)
            }

        }, 15000)
        makeScreenshot(video)
    }
    return <Video className={classes.video} srcObject={stream} onPlay={startScrenshotting} autoPlay muted />
}
type RemoteUserType = {
    streamUser: Node<DBUser>,
    streams: MediaStream[]
}


const RemoteStreamsView = ({ streamUser, streams, ...props }: RemoteUserType) => {
    const classes = useStyles()
    const theme = useTheme()
    const [userStreams, setUserStreams] = useState<MediaStream[]>([])
    const [tabedFullscreen, setTabedFullscreen] = React.useState(false);
    const [tabedFullscreenVideo, setTabedFullscreenVideo] = React.useState<MediaStream | null>(null);

    let dbStreams = useMapById<DBStream>(RoomsDB, streamUser.valueId, true)

    useEffect(() => {
        setUserStreams(streams.filter((stream) => dbStreams[stream.id] !== undefined))
    }, [dbStreams, streams])


    const makePopup = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        let video = event.currentTarget.parentElement?.parentElement?.getElementsByTagName('video')[0] as HTMLVideoElement
        let wnd = window.open('', '', 'width=600,height=400,status=no,scrollbars=no,resizable=yes,location=no')
        if (!wnd) {
            console.log("failed to create popup")
            return
        }
        let streamer = (event.currentTarget.parentElement?.parentElement?.parentElement?.getElementsByTagName('div')[0].getElementsByTagName('span')[0] as HTMLSpanElement).innerText
        wnd.document.title = "Screenshare - " + streamer
        let vc = wnd.document.createElement("video")
        vc.srcObject = video.srcObject
        vc.muted = true
        vc.controls = false
        vc.autoplay = true
        vc.style.width = "100%"
        vc.style.height = "100%"
        wnd.document.body.style.margin = "0px"
        wnd.document.body.style.backgroundColor = theme.palette.background.default
        wnd.document.body.appendChild(
            vc
        )
    }

    const makeTabFullscreen = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        let video = event.currentTarget.parentElement?.parentElement?.getElementsByTagName('video')[0] as HTMLVideoElement
        let streamer = (event.currentTarget.parentElement?.parentElement?.parentElement?.getElementsByTagName('div')[0].getElementsByTagName('span')[0] as HTMLSpanElement).innerText
        setTabedFullscreenVideo(video.srcObject as MediaStream)
        setTabedFullscreen(true)
        let document = (event.target as HTMLButtonElement).ownerDocument
        document.title = "Screenshare - " + streamer
        
        
    }
    const closeTabFullscreen = () => {
        setTabedFullscreen(false)
        document.title = "Screenshare - Rooms"
    }

    return <>
        {userStreams.length !== 0 ?
            <Card className={classes.userCard}>
                <Chip className={classes.memberChip} color="primary" label={streamUser.value?.name} />
                {userStreams.map(stream => {
                    if (stream) {
                        return <div className={classes.previews}
                            key={stream.id}>
                            <Video className={classes.video} srcObject={stream} autoPlay muted />
                            <Paper component={'div'} className={classes.streamControls}>
                                <IconButton onClick={makePopup} >
                                    <PictureInPictureIcon />
                                </IconButton>
                                <IconButton onClick={makeTabFullscreen} >
                                    <WebAssetIcon />
                                </IconButton>
                                <IconButton onClick={makeFullscreen}>
                                    <FullscreenIcon />
                                </IconButton>
                            </Paper>
                        </div>
                    }
                    return null
                })}
                <Dialog fullScreen open={tabedFullscreen} onClose={closeTabFullscreen}>
                    {
                        tabedFullscreenVideo != null ?
                            <div style={{ width: "100%", height: "100%", display: "flex" }}>
                                <Video className={classes.video} srcObject={tabedFullscreenVideo} autoPlay muted />
                            </div>
                            : undefined
                    }
                </Dialog>
            </Card>
            : undefined}

    </>
}

type UserStreamingType = {
    streamUser: Node<DBUser>,
    setStreamingCount(streamCount: number): void,
    removeSelf: () => void,
}
const UserStreaming = ({ streamUser, setStreamingCount, removeSelf, ...props }: UserStreamingType) => {
    const dbStreams = useMapById<DBStream>(RoomsDB, streamUser.valueId, true)

    useEffect(() => {
        return () => {
            removeSelf()
        }
    }, [])

    useEffect(() => {
        let count = Object.values(dbStreams).length
        setStreamingCount(count)
    }, [dbStreams])

    return <></>
}


export const RoomView = () => {
    const classes = useStyles()
    const showAlert = useAlertBar()
    const [user,] = useUser()

    const resizeElement = createRef<HTMLDivElement>()
    const [distinctUsersMap, setDistinctUsersMap] = useState<Map<string, string[]>>(new Map<string, string[]>())
    const [distinctIdMap, setDistinctIdMap] = useState<Map<string, number>>(new Map<string, number>())
    const [howManyStreamsAreInMyRoom, setHowManyStreamsAreInMyRoom] = useState<Number>(0);

    const history = useHistory()

    const [memberId, setMemberId] = useState<string>("")

    const [signal, setSignal] = useState<IonSFUJSONRPCSignal | null>(null)
    const [client, setClient] = useState<Client | null>(null)

    const [streams, setStreams] = useState<MediaStream[]>([])
    const [myStreams, setMyStreams] = useState<LocalStream[]>([])
    const [tickRunning, setTickRunning] = useState<boolean>(true)

    const [width, setWidth] = useState<number>(0)

    const oberserver = useRef(new (window as any).ResizeObserver((entries: any) => {
        const { width } = entries[0].contentRect;
        setWidth(width)
    }))


    const serverSettings = useServerSettings()
    const { id } = useParams<{ id: string }>()
    const room = useValueById<DBRoom>(RoomsDB, id)
    let users = useListById<DBUser>(RoomsDB, id, true)


    useEffect(() => {
        let map = new Map<string, string[]>()
        let idMap = new Map<string, number>()
        users.forEach(user => {
            if (user.value){
                let list = map.get(user.value.name) || []
                list.push(user.valueId)
                map.set(user.value.name, list)
            }
        })
        
        setDistinctUsersMap(map)
    }, [users])

    const setStreamingCount = (valueId: string, add: number) => {
        let map = new Map<string, number>(distinctIdMap)
        map.set(valueId, add)
        setDistinctIdMap(map)
    }

    const countStreaming = (ids: string[]) => {
        let count = 0;
        ids.forEach(id => {
            count += distinctIdMap.get(id) || 0
        })

        return count
    }  

    const removeSelf = (valueId: string) => {
        let map = new Map<string, number>(distinctIdMap)
        map.delete(valueId)
        setDistinctIdMap(map)
    }

    const getStreamingArray = () => {
        let map = new Map<string, number>()
        let c = 0;
        distinctUsersMap.forEach((value, key) => {
            let count = countStreaming(value)
            map.set(key, count)
            c += count
        })

        c -= distinctIdMap.get(memberId) ?? 0 

        if(c != howManyStreamsAreInMyRoom){
            setHowManyStreamsAreInMyRoom(c);
        } 

        return Array.from(map).sort((a, b) => b[1] - a[1])
    }

    useEffect(() => {
        if (resizeElement.current) {
            oberserver.current.observe(resizeElement.current)
        }

        return () => {
            oberserver.current.disconnect()
        }
    }, [resizeElement, oberserver])


    useInterval(() => {
        if (memberId !== "") {
            if (!window.location.pathname.includes(id)) {
                leaveRoom()
            } else {
                request<any>("/rooms/heartbeat", { 'id': memberId }, 'PUT', showAlert)
            }
        }
    }, 10000)

    const joinRoom = () => {
        if (user) {
            request<any>("/rooms/member", { 'name': user.name, 'room': id }, 'PUT', showAlert)
                .then(result => {
                    if (result.success) {
                        let memId = result.success as string
                        setMemberId(memId)
                    }
                })
        }
    }

    const leaveRoom = () => {
        if (tickRunning) {

            myStreams.forEach(s => {
                closeStream(s)
            })

            request<any>("/rooms/member", { 'id': memberId }, "DELETE")

            setTickRunning(false)
        }
    }

    useEffect(() => {
        joinRoom();

        if (!serverSettings) {
            showAlert((close: () => void) => (
                <Alert onClose={close} severity="error">
                    {"ServerSettings missing"}
                </Alert>
            ))
        } else {
            var s = new IonSFUJSONRPCSignal(serverSettings.sfuUrl)
            setSignal(s)
        }
    }, [])

    useEffect(() => {
        window.addEventListener("popstate", leaveRoom)
        window.addEventListener("unload", leaveRoom)
        window.addEventListener("beforeunload", leaveRoom)

        let historyListener = history.listen(() => {
            leaveRoom()
        })

        return () => {
            setTimeout(() => {
                window.removeEventListener("popstate", leaveRoom)
                window.removeEventListener("unload", leaveRoom)
                window.removeEventListener("beforeunload", leaveRoom)
                historyListener()
            }, 1000)

        }
    }, [memberId, myStreams])


    useEffect(() => {
        if (signal) {
            if (!room) {
                showAlert((close: () => void) => (
                    <Alert onClose={close} severity="error">
                        {"Room invalid"}
                    </Alert>
                ))
            } else {
                var c = new Client(signal, {
                    codec: "vp8",
                    iceServers: [
                        {
                            urls: [
                                "turn:nix2.allesctf.net:13478"
                            ],
                            username: room.turnUsername,
                            credential: room.turnPassword
                        }
                    ],
                    //iceTransportPolicy: "relay"
                })
                signal.onopen = () => c.join(room.code)

                setClient(c)

                c.ontrack = (track: MediaStreamTrack, stream: RemoteStream) => {
                    if (track.kind === "video") {
                        stream.preferLayer("high")
                        setStreams(streams => [...streams, stream])

                        stream.onremovetrack = () => setStreams(streams => streams.filter((s) => s !== stream))
                    }
                };
            }
        }
    }, [room])

    async function share() {
        const local = await LocalStream.getDisplayMedia({
            video: true,
            audio: false,
            simulcast: false,
            codec: 'vp8',
            resolution: 'fhd',
        });
        client?.publish(local);
        setMyStreams(myStreams => [...myStreams, local])

        local.getVideoTracks().forEach(track => {
            track.onended = () => {
                closeStream(local)
            }
        });

        request<any>("/rooms/stream", { 'memberId': memberId, 'streamId': local.id }, 'PUT', showAlert)
    }

    const closeStream = (stream: LocalStream) => {
        stream.getTracks().forEach(track => track.stop())
        stream.unpublish()
        console.log("closed")

        setMyStreams(myStreams.filter((s) => s !== stream))
        request<any>("/rooms/stream", { 'memberId': memberId, 'streamId': stream.id }, 'DELETE', showAlert)
    }



    return <div className={classes.root} ref={resizeElement}>
        {
            users.map(userNode => (
                <UserStreaming 
                    streamUser={userNode} 
                    setStreamingCount={(add: number) => setStreamingCount(userNode.valueId, add)} 
                    key={userNode.valueId} 
                    removeSelf={() => removeSelf(userNode.valueId)}
                    />
            ))
        }
        <Paper component={'div'} className={classes.users}>
                {getStreamingArray().map(([name, streamCount]) => {
                        return <Chip 
                            key={name} 
                            className={classes.userChip} 
                            color={streamCount === 0 ? "secondary" : "primary"}
                            label={name} 
                            />
                })}
        </Paper>
        <div className={classes.remoteUsers}>

            {howManyStreamsAreInMyRoom !== 0 ?
                users.map(user => {
                    if (user) {
                        return <RemoteStreamsView key={user.valueId} streamUser={user} streams={streams} />
                    }
                    return null
                }) :
                <Card className={classes.noStreams}>
                    <CardContent>
                        <Typography component="p">
                            No streams yet ;(
                    </Typography>
                    </CardContent>
                </Card>
            }
        </div>
        <div className={classes.ownStreamsContainer} style={{ width: width }}>

            <div className={classes.ownStreams}>
                {myStreams.map(stream => {
                    if (stream) {
                        return <Card className={classes.ownPreviews}
                            key={stream.id}>
                            <CardContent>

                                <LocalStreamView stream={stream as MediaStream} memberId={memberId} />
                                <Paper component={'div'} className={classes.streamControls}>
                                    <IconButton onClick={(e) => closeStream(stream)}>
                                        <StopScreenShareIcon />
                                    </IconButton>
                                    <IconButton onClick={makeFullscreen}>
                                        <FullscreenIcon />
                                    </IconButton>
                                </Paper>
                            </CardContent>
                        </Card>
                    }
                    return null
                })}
            </div>
        </div>
        < Fab color="primary"
            className={classes.fab}
            aria-label="add"
            onClick={share}>
            <ScreenShareIcon />
        </Fab>
    </div >
}