import { Card, CardContent, CardActionArea, Fab, Chip, IconButton  } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { DeleteForever as DeleteForeverIcon, Add as AddIcon, LockOpen as LockOpenIcon, Lock as LockIcon } from '@material-ui/icons'
import { Link } from 'react-router-dom';
import { createDB, useAlertBar, useListById, useListByName, wsBackend, AllesLogo, Node, useMapById } from 'react-alles-app';
import { request } from '../Request';
import { useTitle } from '../App';
import { ConfirmableIconButton } from '../Components/ConfirmableButton';
import React, { useEffect, useState } from 'react';
import { useInterval } from '../useInterval';
import clsx from 'clsx';

export interface DBRoom {
    code: string
    turnUsername: string
    turnPassword: string
}

export interface DBUser {
    name: string
}

export interface DBStream {
    streamId: string
    thumbnail: string
}

/*

[Rooms]
code
turnUsername
turnPassword

[User]
name

[StreamID -> User]

*/

export const RoomsDB = createDB(wsBackend("/rooms/db"));

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        overflowY: "auto",
        display: "flex",
        justifyContent: "center",
        padding: theme.spacing(2),
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    lock :{
        position: 'fixed',
        right: theme.spacing(2),
    },
    room: {
        margin: theme.spacing(1),
        padding: theme.spacing(2),
        position: "relative",
    },
    innerRoom: {
        width: "384px",
        height: "216px",
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        backgroundPosition: "center",
    },
    rooms: {
        height: "100%",
        display: "flex",
        flexFlow: "row wrap",
    },
    icon: {
        position: "absolute",
        top: 0,
        right: 0,
    },
    members: {
        left: 0,
        bottom: 0,
        position: "absolute",
        padding: theme.spacing(0.5),
    },
    memberChip: {
        margin: theme.spacing(0.5),
    },
    logo: {
        height: "auto",
        width: "100%",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        marginTop: "auto",
        marginBottom: "auto",
        position: "absolute",
        animation: `$pulse 8s infinite ${theme.transitions.easing.easeInOut}`,
    },
    "@keyframes pulse": {
        "0%": {
            transform: "scale(0.8)"
        },
        "50%": {
            transform: "scale(1.0)"
        },
        "100%": {
            transform: "scale(0.8)"
        },

    }
}))

type UserThumbnailType = {
    streamUser: Node<DBUser>,
    previewCb(image: string): void,
    counter2: number
}
const UserThumbnail = ({ streamUser, previewCb, counter2, ...props }: UserThumbnailType) => {
    let dbStreams = useMapById<DBStream>(RoomsDB, streamUser.valueId, true)

    useEffect(() => {
        if (Object.values(dbStreams)[counter2 % Object.values(dbStreams).length] != null) {
            previewCb(Object.values(dbStreams)[counter2 % Object.values(dbStreams).length].value!!.thumbnail)
        }
    }, [counter2, dbStreams])

    return <></>
}

type UserStreamingType = {
    streamUser: Node<DBUser>,
    setStreamingCount(streamCount: number): void,
    removeSelf: () => void,
}
const UserStreaming = ({ streamUser, setStreamingCount, removeSelf, ...props }: UserStreamingType) => {
    const dbStreams = useMapById<DBStream>(RoomsDB, streamUser.valueId, true)

    useEffect(() => {
        return () => {
            removeSelf()
        }
    }, [])

    useEffect(() => {
        let count = Object.values(dbStreams).length
        setStreamingCount(count)
    }, [dbStreams])

    return <></>
}

const RoomCard = ({ locked, id, value }: { locked: boolean, id: string, value: DBRoom }) => {
    const classes = useStyles()
    const theme = useTheme()
    const [preview, setPreview] = useState<string>("")
    const [previewUser, setPreviewUser] = useState<Node<DBUser>>()
    const [counter, setCounter] = useState<number>(0)
    const [counter2, setCounter2] = useState<number>(0)
    const [distinctUsersMap, setDistinctUsersMap] = useState<Map<string, string[]>>(new Map<string, string[]>())
    const [distinctIdMap, setDistinctIdMap] = useState<Map<string, number>>(new Map<string, number>())

    let users = useListById<DBUser>(RoomsDB, id, true)

    useEffect(() => {
        let map = new Map<string, string[]>()
        let idMap = new Map<string, number>()
        users.forEach(user => {
            if (user.value){
                let list = map.get(user.value.name) || []
                list.push(user.valueId)
                map.set(user.value.name, list)
            }
        })
        
        setDistinctUsersMap(map)
    }, [users])

    const setStreamingCount = (valueId: string, add: number) => {
        let map = new Map<string, number>(distinctIdMap)
        map.set(valueId, add)
        setDistinctIdMap(map)
    }

    const countStreaming = (ids: string[]) => {
        let count = 0;
        ids.forEach(id => {
            count += distinctIdMap.get(id) || 0
        })

        return count
    }  
    
    const getStreamingArray = () => {
        let map = new Map<string, number>()
        distinctUsersMap.forEach((value, key) => {
            map.set(key, countStreaming(value))
        })

        return Array.from(map).sort((a, b) => b[1] - a[1])
    }

    const removeSelf = (valueId: string) => {
        let map = new Map<string, number>(distinctIdMap)
        map.delete(valueId)
        setDistinctIdMap(map)
    }

    const deleteRoom = (e: React.MouseEvent) => {
        e.preventDefault()
        request("/rooms/room", { id: id }, "DELETE")
    }

    useInterval(() => {
        setCounter((ctr) => ctr + 1)
        setPreviewUser(users[counter % users.length])
    }, 5000)

    useEffect(() => {
        if (counter % users.length === 0) {
            setCounter2(c => c + 1)
        }
    }, [counter, users])

    useEffect(() => {
        setPreviewUser(users[counter % users.length])
    }, [users])

    return <Card className= {clsx(locked ? undefined: "wiggle" , classes.room)}>
        {
            users.map(userNode => (
                <UserStreaming 
                    streamUser={userNode} 
                    setStreamingCount={(add: number) => setStreamingCount(userNode.valueId, add)} 
                    key={userNode.valueId} 
                    removeSelf={() => removeSelf(userNode.valueId)}
                    />
            ))
        }
        <CardActionArea className={classes.innerRoom}
            component={Link}
            to={"/streaming/room/" + id}
            style={{ backgroundImage: `url("${preview}")` }}
        >
            <CardContent>
                {preview ?
                    undefined : <AllesLogo color={theme.palette.text.secondary} className={classes.logo} ></AllesLogo>
                }
                <div className={classes.members}>
                    {getStreamingArray().map(([name, streamCount]) => {
                        return <Chip 
                            key={name} 
                            className={classes.memberChip} 
                            color={streamCount === 0 ? "secondary" : "primary"}
                            label={name} 
                            />
                    })}
                </div>
                {previewUser !== undefined ?
                    <UserThumbnail counter2={counter2} key={previewUser.valueId} streamUser={previewUser} previewCb={setPreview} />
                    : undefined}
            </CardContent>
        </CardActionArea>
        {
            locked? <> </>: 
            <IconButton  className={classes.icon} onClick={deleteRoom}>
                <DeleteForeverIcon color="inherit" fontSize="small" />
            </IconButton>
        }
    </Card>
}

export const StreamingTool: React.FC = () => {
    const classes = useStyles()
    const rooms = useListByName<DBRoom>(RoomsDB, 'rooms', true)
    const showAlert = useAlertBar()
    const [locked, setLocked] = useState<boolean>(true);

    useTitle("Screenshare - Rooms")

    const createRoom = () => {
        request<any>("/rooms/room", {}, 'PUT', showAlert)
    }

    const toggleLock = () => {
        setLocked(!locked);
    }

    return <div className={classes.root}>
        <div className={classes.rooms}>
            {rooms.map(node =>
                node.value ? <RoomCard locked={locked} id={node.valueId} value={node.value} key={node.valueId} /> : undefined
            )}
        </div>
        {
            locked ? <> </> : 
            <Fab color="primary"
            className={classes.fab}
            aria-label="add"
            onClick={() => createRoom()}>
            <AddIcon />
        </Fab> 
        }
       
        <Fab color={locked? "primary": "secondary"}
            className={classes.lock}
            aria-label="unlock"
            onClick={() => toggleLock()}>
            {
                locked? <LockIcon /> : <LockOpenIcon /> 
            }
        </Fab>
    </div>
}