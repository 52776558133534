import { generateKey, RSAKey, emptyKey, validate, blunders, complete, Blunders, encrypt, decrypt } from './rsa'
import * as React from 'react'
import { parseKey, parsePrivateKey } from 'sshpk'
import { Container, TextField, Paper, Tabs, Tab, Button, ButtonGroup, Typography, Grid } from '@material-ui/core';
import { rsaKeyExporters } from './rsa_export';
import { Alert } from '@material-ui/lab';

interface State {
    key: RSAKey,
    importField: string,
    importPass: string,
    importFailed: boolean,
    analysis: Blunders | null,
}

function any(obj: Object): boolean {
    for (let key of Object.keys(obj))
        if ((obj as any)[key])
            return true
    return false
}

function isStrictSuperset(a: Object, b: Object): boolean {
    let mA = 0
    let mB = 0
    for (let key of Object.keys(a)) {
        let vA = (a as any)[key]
        let vB = (b as any)[key]
        if (vA !== null && vB === null)
            mB++
        if (vA === null && vB !== null)
            mA++
    }
    return mA === 0 && mB > 0
}

class EncryptDecrypt extends React.PureComponent<{ keyData: RSAKey }, { input: bigint | null, output: bigint | null }> {
    constructor(props: { keyData: RSAKey }) {
        super(props)
        this.state = { input: null, output: null }
    }
    render() {
        let { n, e, d } = this.props.keyData
        return <Paper style={{ padding: '1.5em' }}>
            <TextField
                label="Message (in decimal)"
                multiline
                rows={6}
                fullWidth
                variant='filled'
                onChange={(v) => {
                    let val: bigint | null = BigInt(v.currentTarget.value as string)
                    if (val <= 0n)
                        val = null
                    this.setState({ input: val })
                }}
            />
            {this.state.input !== null && n !== null && this.state.input >= n ? (
                <Alert severity="warning">{"msg >= n, msg will be truncated"}</Alert>
            ) : null}
            <div style={{ display: 'flex', justifyContent: 'space-around', padding: '0.5em' }}>
                <Button
                    disabled={n === null || e === null || this.state.input === null}
                    onClick={() => this.setState({ output: encrypt(this.state.input!, { n: n!, e: e! }) })}
                >Encrypt (msg^e % n)</Button>
                <Button
                    disabled={n === null || d === null || this.state.input === null}
                    onClick={() => this.setState({ output: decrypt(this.state.input!, { n: n!, e: e!, d: d! }) })}
                >Decrypt (msg^d % n)</Button>
            </div>
            <TextField
                label="Result (in decimal)"
                disabled
                multiline
                variant='outlined'
                rows={6}
                fullWidth
                value={this.state.output ? this.state.output.toString() : ''}
            />
        </Paper>
    }
}

const KeyExport: React.FC<{ keyData: RSAKey }> = ({ keyData }) => {
    let [selected, setSelected] = React.useState(0)

    return <Paper style={{ padding: '1.5em', paddingTop: '0.4em' }}>
        <Tabs
            indicatorColor="primary"
            textColor="primary"
            centered
            value={selected}
            onChange={(_, i) => setSelected(i)}
        >
            {rsaKeyExporters.map(({ name }) =>
                <Tab label={name} key={name} />
            )}
        </Tabs>
        <TextField style={{ marginTop: '0.5em' }} fullWidth disabled variant='outlined' multiline rows={6} value={rsaKeyExporters[selected].exportKey(keyData)} />
    </Paper>
}

export class RsaTool extends React.Component<{}, State> {
    constructor(props: {}) {
        super(props)
        this.state = {
            key: emptyKey(),
            importField: "",
            importPass: "",
            importFailed: false,
            analysis: null
        }
        this.onImport = this.onImport.bind(this)
    }

    onImport() {
        let key = null
        try {
            key = parsePrivateKey(this.state.importField, 'auto', { passphrase: this.state.importPass })
            console.log("parsed private key", key)
        }
        catch (e) {
            try {
                key = parseKey(this.state.importField, 'auto', { passphrase: this.state.importPass })
                if (key.type !== 'rsa') {
                    alert("key is not of type rsa: " + key.type)
                    return
                }
                console.log("parsed public key", key)
            } catch (e) {
            }
        }
        if (key === null) {
            this.setState({ importFailed: true })
            return
        }
        let result = emptyKey()
        for (let part of (key as any).parts) {
            let { name, data }: { name: string, data: Uint8Array } = part
            // eslint-disable-next-line no-loop-func
            let val = data.reduce((acc, val) => (acc << 8n) | BigInt(val), 0n)
            console.log(name, '' + val)
            switch (name) {
                case 'n':
                    result.n = val
                    continue
                case 'e':
                    result.e = val
                    continue
                case 'd':
                    result.d = val
                    continue
                case 'p':
                    result.p = val
                    continue
                case 'q':
                    result.q = val
                    continue
            }
        }

        if (result.p !== null && result.q !== null && result.p > result.q)
            [result.p, result.q] = [result.q, result.p]
        this.setState({ key: result, importFailed: false })
    }

    updateKey(e: any, cb: (val: bigint | null) => Partial<RSAKey>) {
        let val: bigint | null = BigInt(e.target.value)
        if (val <= 0n)
            val = null
        this.setState({
            key: {
                ...this.state.key,
                ...cb(val)
            }
        })
    }

    componentDidUpdate() {
        (window as any).requestIdleCallback(() =>
            blunders(this.state.key).then(analysis => {
                let changed = JSON.stringify(analysis) !== JSON.stringify(this.state.analysis)
                if (changed)
                    this.setState({ analysis })
            }), { timeout: 1000 })
    }

    componentDidMount() {
        document.title = "CTF Tool - RSA"
    }

    render() {
        let { n, e, d, p, q } = this.state.key
        let keyContradictions = validate(this.state.key)
        let { q: qWrong, p: pWrong, d: dWrong } = keyContradictions
        let isValid = !any(keyContradictions);
        let { pComposite, qComposite, eComposite, nSmall, notCoprime, eDividesPhiN } = this.state.analysis || { pComposite: false, qComposite: false, eComposite: false, nSmall: false, notCoprime: false, eDividesPhiN: false }
        let completed = this.state.key
        if (isValid)
            completed = complete(this.state.key)
        let canAutofill = isStrictSuperset(completed, this.state.key)

        let fieldErrors = (obj: { [key: string]: boolean }) => {
            let helperTexts = []
            for (let k of Object.keys(obj)) {
                if (obj[k] === true)
                    helperTexts.push(k);
            }
            return {
                error: helperTexts.length !== 0,
                helperText: helperTexts.join(", ")
            }
        }

        return <Container style={{ paddingTop: '1.5em' }} maxWidth='lg'>
            <Typography variant="h4" gutterBottom>RSA Tool</Typography>
            <Paper style={{ padding: '1.5em', paddingBottom: 0, marginBottom: '1em' }}>
                <TextField
                    label="Private or public key in PEM format"
                    fullWidth
                    onChange={(v) => this.setState({ importField: v.currentTarget.value as string, importFailed: false })}
                    rows={4}
                    multiline
                    variant="filled"
                    style={{ paddingBottom: '1em' }}
                />
                <TextField
                    label="Passphrase"
                    fullWidth
                    onChange={(v) => this.setState({ importPass: v.currentTarget.value as string, importFailed: false })}
                    variant="filled"
                    style={{ paddingBottom: '1em' }}
                />
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button
                        variant='outlined'
                        onClick={this.onImport}
                        disabled={!this.state.importField}
                        color={this.state.importFailed ? 'secondary' : undefined}
                        style={{ marginRight: '0.5em' }}
                    >
                        Import this key
                    </Button>
                    <Button
                        variant='outlined'
                        onClick={() => this.setState({ key: completed })}
                        disabled={!canAutofill}
                        color={canAutofill ? "primary" : undefined}
                        style={{ marginRight: '0.5em' }}
                    >
                        Autofill
                    </Button>
                    <ButtonGroup style={{ marginRight: '0.5em' }}>
                        <Button onClick={async _ => {
                            let key = await generateKey(30, { ...emptyKey(), e: this.state.key.e })
                            this.setState({ key })
                        }}>Generate small</Button>
                        <Button onClick={async _ => {
                            let key = await generateKey(256, { ...emptyKey(), e: this.state.key.e })
                            this.setState({ key })
                        }}>big</Button>
                    </ButtonGroup>
                    <Button
                        style={{ marginRight: '0.5em' }}
                        variant='outlined'
                        onClick={_ => this.setState({
                            key: {
                                ...emptyKey(),
                                n: this.state.key.n,
                                e: this.state.key.e
                            }
                        })}
                        disabled={p === null && q === null && d === null}
                    >
                        Clear private
                    </Button>
                    <Button
                        variant='outlined'
                        onClick={_ => this.setState({ key: emptyKey() })}
                    >
                        Clear all
                    </Button>
                </div>
                <br />
            </Paper>
            <Paper style={{ padding: '1.5em', marginBottom: '1em' }}>
                <div>
                    <Typography variant="subtitle1" gutterBottom>Public Information</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={5}>
                            <TextField
                                label="Modulus (n)"
                                variant='outlined'
                                fullWidth
                                value={n?.toString() || ""}
                                onChange={e => this.updateKey(e, val => ({ n: val }))}
                                {...fieldErrors({ 'Weak!': nSmall })}
                            />
                        </Grid>

                        <Grid item xs={5}>
                            <TextField
                                label="PublicExp (e)"
                                variant='outlined'
                                fullWidth
                                value={e?.toString() || ""}
                                onChange={e => this.updateKey(e, val => ({ e: val }))}
                                {...fieldErrors({
                                    'Composite!': eComposite,
                                    'e divides φ(n)': eDividesPhiN,
                                    'e not coprime to φ(n)': notCoprime,
                                })}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Button
                                    variant='outlined'
                                    disabled={this.state.key.n === null}
                                    onClick={() => window.open("http://factordb.com/index.php?query=" + this.state.key.n, "_blank")}>
                                    Factor n
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                </div>
                <div style={{ paddingTop: '2em' }}>
                    <Typography variant="subtitle1" gutterBottom>Private Information</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={2}>
                            <TextField
                                fullWidth
                                variant='outlined'
                                label="Private Exponent (d)"
                                value={d ? d.toString() : ""}
                                onChange={e => this.updateKey(e, val => ({ d: val }))}
                                {...fieldErrors({ "Wrong!": dWrong })}
                            />
                        </Grid>
                        <Grid item xs={5}>
                            <TextField
                                fullWidth
                                variant='outlined'
                                label="Factor1 (p)"
                                value={p ? p.toString() : ""}
                                onChange={e => this.updateKey(e, val => ({ p: val }))}
                                {...fieldErrors({ "Wrong!": pWrong, "Composite!": pComposite })}
                            />
                        </Grid>
                        <Grid item xs={5}>
                            <TextField
                                fullWidth
                                variant='outlined'
                                label="Factor2 (q)"
                                value={q ? q.toString() : ""}
                                onChange={e => this.updateKey(e, val => ({ q: val }))}
                                {...fieldErrors({ "Wrong!": qWrong, "Composite!": qComposite })}
                            />
                        </Grid>
                    </Grid>
                </div>
            </Paper>
            <div style={{ marginBottom: '1em' }}>
                <KeyExport keyData={this.state.key} />
            </div>
            <EncryptDecrypt keyData={this.state.key} />
        </Container>
    }
}
