import React, { useState, ChangeEvent, FormEvent } from 'react';
import { TextField, Container, Grid, Button } from '@material-ui/core';
import { useUser } from '../User';
import { useAlertBar, Loading } from 'react-alles-app';
import { request } from '../Request';
import { useTitle } from '../App';

export const Login = () => {
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [loggingIn, setLoggingIn] = useState(false)
    const [, setUser] = useUser()
    const showAlert = useAlertBar()

    useTitle("CTF Tool - Login")


    const handleUsernameChange = (event: ChangeEvent<HTMLInputElement>) => {
        setUsername(event.currentTarget.value)
    }

    const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
        setPassword(event.currentTarget.value)
    }

    const login = (e: FormEvent) => {
        e.preventDefault()
        if (loggingIn) return false
        setLoggingIn(true)
        const name = username

        request<any>("/auth/login", { 'username': name, 'password': password }, 'POST', showAlert)
            .then(result => {
                setLoggingIn(false)
                if (result.success)
                    setUser({ name })
            })

        return false
    }

    return <>
        <Container maxWidth="md" style={{ marginTop: 30 }}>
            <form onSubmit={login}>
                <Grid container spacing={3} justify="center" alignItems="center">
                    <Grid item xs={12}>
                        <TextField label="Name" variant="outlined" value={username} onChange={handleUsernameChange} fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField label="Password" variant="outlined" type="password" value={password} onChange={handlePasswordChange} fullWidth />
                    </Grid>
                    <Grid item>
                        <Button type="submit" variant="contained" color="secondary" size="large">{loggingIn ? <Loading /> : 'Log In'}</Button>
                    </Grid>
                </Grid>
            </form>
        </Container>
    </>
}