import {
	isProbablyPrime as _isProbablyPrime, primeSync as _primeSync,
	lcm, gcd, modInv, modPow
} from "bigint-crypto-utils";

let primeTestCache: { [key: string]: boolean } = {}
async function isProbablyPrime(inp: bigint): Promise<boolean> {
	console.log("checking", inp, "for isPrime")
	if (primeTestCache['' + inp] !== undefined)
		return primeTestCache['' + inp]
	// disable async workers due to js minifier bug
	let res = await _isProbablyPrime(inp, 16, /*disableWorkers=*/true)
	primeTestCache['' + inp] = res
	console.log("checking", inp, "done")
	return res
}

async function prime(primeBits: number) {
	// disable async workers due to js minifier bug
	return _primeSync(primeBits);
}

export interface RSAPair {
	n: bigint, e: bigint, // public
	d: bigint,            // private
	p: bigint, q: bigint  // p <= q
}

export type PubKey = Pick<RSAPair, 'n' | 'e'>
export type PrivKey = Pick<RSAPair, 'n' | 'e' | 'd'>

export type Nullable<T> = { [P in keyof T]: T[P] | null }
export type RSAKey = Nullable<RSAPair> // valid even if every field is null

export function emptyKey(): RSAKey {
	return { n: null, e: null, p: null, q: null, d: null }
}

export async function generateKey(primeBits: number, key?: RSAKey): Promise<RSAPair> {
	if (primeBits < 10)
		primeBits = 10 // ensure n > e
	let { n, e, p, q, d } = key || emptyKey()
	if (!e || e <= 2n || e > (16n << BigInt(primeBits)) || !(await isProbablyPrime(e)))
		// some part of the 'production build' pipeline tries to optimize this expr
		// and replaces it with Math.pow(2n, 16n) instead of constant-folding ??
		e = /* 2n ** 16n + 1n = */ 65537n;

	if (!q)
		q = await prime(primeBits)
	if (!p)
		p = await prime(primeBits)

	while (gcd(lcm(p - 1n, q - 1n), e) !== 1n) {
		p = await prime(primeBits)
		q = await prime(primeBits)
	}

	n = p * q
	let lambda = lcm(p - 1n, q - 1n)
	d = modInv(e, lambda) as bigint // this'll succeed
	if (p > q)
		[p, q] = [q, p]
	return { n, e, d, p, q }
}

// Set of potential fuckups
export interface Blunders {
	qComposite: boolean,
	pComposite: boolean,
	eComposite: boolean
	notCoprime: boolean,
	nSmall: boolean,
	eDividesPhiN: boolean
}

export async function blunders(key: RSAKey): Promise<Blunders> {
	let res = {
		qComposite: false,
		pComposite: false,
		notCoprime: false,
		nSmall: false,
		eComposite: false,
		eDividesPhiN: false
	}

	if (key.q !== null)
		res.qComposite = !await isProbablyPrime(key.q)

	if (key.p !== null)
		res.pComposite = !await isProbablyPrime(key.p)

	if (key.e !== null)
		res.eComposite = !await isProbablyPrime(key.e)

	if (key.p !== null && key.q !== null && key.e !== null && gcd(lcm(key.p, key.q), key.e) !== 1n)
		res.notCoprime = true;

	if (key.n !== null && key.n < (1n << 250n))
		res.nSmall = true;

	if (key.p !== null && key.q !== null && key.e !== null)
		res.eDividesPhiN = gcd((key.p - 1n) * (key.q - 1n), key.e) !== 1n

	return res
}

// checks RSAKey for direct contradictions
export interface Contradictions {
	p: boolean,
	q: boolean,
	d: boolean,
	e: boolean,
}
export function validate(key: RSAKey): Contradictions {
	let res = { p: false, q: false, d: false, e: false }
	let { n, e, d, p, q } = key;
	if (p === 1n) res.p = true
	if (q === 1n) res.q = true
	if (d === 1n) res.d = true
	if (n !== null) {
		if (q !== null && (q * (n / q)) !== n)
			res.q = true
		if (p !== null && (p * (n / p)) !== n)
			res.p = true

		try {
			if (e !== null && d !== null && p !== null && q !== null &&
				!res.q && !res.p && p > 1n && q > 1n &&
				modInv(e, lcm(p - 1n, q - 1n)) !== d)
				res.d = true
		} catch (RangeError) {
			res.e = true;
		}
	}
	return res
}

// Ported from pycryptodome.RSA.construct
export function recoverPQ({ e, n, d }: Pick<RSAPair, 'e' | 'n' | 'd'>): RSAPair | null {
	let p = 0n
	// Compute factors p and q from the private exponent d.
	// We assume that n has no more than two factors.
	// See 8.2.2(i) in Handbook of Applied Cryptography.
	let ktot = d * e - 1n
	// The quantity d*e-1 is a multiple of phi(n), even,
	// and can be represented as t*2^s.
	let t = ktot
	while (t % 2n === 0n)
		t /= 2n
	//  Cycle through all multiplicative inverses in Zn.
	// The algorithm is non-deterministic, but there is a 50% chance
	// any candidate a leads to successful factoring.
	// See "Digitalized Signatures and Public Key Functions as Intractable
	// as Factorization", M. Rabin, 1979
	let spotted = false
	let a = 2n
	while (!spotted && a < 100n) {
		let k = t
		// Cycle through all values a^{t*2^i}=a^k
		while (k < ktot) {
			let cand = modPow(a, k, n)
			// Check if a^k is a non-trivial root of unity (mod n)
			if (cand !== 1n && cand !== (n - 1n) && modPow(cand, 2n, n) === 1n) {
				// We have found a number such that (cand-1)(cand+1)=0 (mod n).
				// Either of the terms divides n.
				p = gcd(n, cand + 1n)
				spotted = true
				break
			}
			k *= 2n
		}
		// This value was not any good... let's try another!
		a += 2n
	}
	if (!spotted || (n % p) !== 0n)
		return null
	let q = n / p
	if (p > q)
		[p, q] = [q, p]
	return { n, e, d, p, q }
}

// we'll assume that the key has been validated
// complete should return a valid superset of the input
export function complete({ n, e, d, p, q }: RSAKey): RSAKey {
	if (n && p && !q)
		q = n / p
	if (n && q && !p)
		p = n / q
	if (n && e && d && !p && !q) {
		let res = recoverPQ({ n, e, d })
		if (res && res.p !== null)
			p = res.p
		if (res && res.q !== null)
			q = res.q
	}
	if (p && q && p > q)
		[p, q] = [q, p]

	if (!d && e && p && q) {
		d = modInv(e, lcm(p - 1n, q - 1n))
		// modInv might return NaN if e divides phi(n)
		if (typeof d !== "bigint" && isNaN(d as any))
			d = null;
	}
	if (!e && d && p && q)
		e = modInv(d, lcm(p - 1n, q - 1n))
	if (!n && p && q)
		n = p * q
	return { n, e, d, p, q }
}

export function encrypt(v: bigint, key: PubKey) {
	return modPow(v, key.e, key.n)
}

export function decrypt(v: bigint, key: PrivKey) {
	return modPow(v, key.d, key.n)
}

