import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { App } from './App';
import * as serviceWorker from './serviceWorker';
import { UserProvider, Session, LocalSessionProvider, ServerSettingsProvider, ServerSettings } from './User';
import { BrowserRouter } from 'react-router-dom';

const session = (document as any).session as Session | undefined
const serverSettings = (document as any).serverSettings as ServerSettings | undefined

ReactDOM.render(
  <React.StrictMode>
    <UserProvider session={session}>
      <ServerSettingsProvider serverSettings={serverSettings}>
        <LocalSessionProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </LocalSessionProvider>
      </ServerSettingsProvider>
    </UserProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
