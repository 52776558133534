import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { PadsDB, Task } from './Pads';
import { CodiPad } from './CodiPad';
import { Typography, makeStyles, Switch, FormControlLabel, FormGroup } from '@material-ui/core';
import { useValueById, useAlertBar } from 'react-alles-app';
import { useUser } from '../User';
import { request } from '../Request';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        margin: 10,
        justifyContent: 'center'
    },
    finishedSwitch: {
        color: theme.palette.primary.light
    }
}))

export const TaskView = () => {
    const { id } = useParams<{ id: string }>()
    const [user,] = useUser()
    const task = useValueById<Task>(PadsDB, id)
    const showAlert = useAlertBar()

    const classes = useStyles()

    useEffect(() => {
        if (task)
            document.title = "CTF Tool - " + task.name
    }, [task])

    if (!task) return <></>

    const isAssigned = task.assignees.includes(user!!.name)

    const handleAssign = () => {
        request("/pads/task/assigned", { "id": id }, "post", showAlert)
    }

    const handleFinish = () => {
        request("/pads/task/finished", { "id": id }, "post", showAlert)
    }

    return <>
        <div className={classes.root}>
            <Typography style={{ flex: 1 }}>Assigned: <Typography color="textSecondary">{task.assignees.length === 0 ? <>&nbsp;</> : task.assignees.join(", ")}</Typography></Typography>
            <Typography style={{ flex: 1, textAlign: 'center' }} variant="h4" component="h2">
                {task.name}
            </Typography>
            <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
                <FormGroup row>
                    <FormControlLabel
                        control={<Switch className={classes.finishedSwitch} checked={task.finished} onChange={handleFinish} />}
                        label="Finished"
                    />
                    <FormControlLabel
                        control={<Switch color="secondary" checked={isAssigned} onChange={handleAssign} />}
                        label="Assigned"
                    />
                </FormGroup>

            </div>
        </div>
        <CodiPad padId={task.padId} mode={'edit'} />
    </>
}