import React from 'react'
import { CodiPad } from './CodiPad'
import { WithSelectedCtf } from './Pads'
import { useTitle } from '../App'

export const CtfOverview = WithSelectedCtf(({ ctf }) => {
    useTitle("CTF Tool - Overview")

    return <>
        <CodiPad padId={ctf.value!!.generalId} mode={'both'} />
    </>
})