import React from 'react'
import { useTheme } from '@material-ui/core/styles'
import { useTitle } from '../App'

export const Cyberchef = () => {
    const theme = useTheme()

    useTitle("CTF Tool - Cyberchef")

    return <iframe height='100%' title="Cyberchef" src={"https://gchq.github.io/CyberChef/?theme=" + (theme.palette.type === 'dark' ? 'dark' : 'classic')} />
}