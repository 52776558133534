import React, { useState, useContext } from 'react';
import { Ctf } from './Pads/Pads';
import { Node, useLocalStorage } from 'react-alles-app'

export interface Session {
    name?: string,
    role?: string,
}

export interface ServerSettings {
    codiUrl: string,
    sfuUrl: string
}

export interface User {
    name: string
}

export interface LocalSession {
    selectedCtf: Node<Ctf> | null
}

export const UserContext = React.createContext<[User | undefined, (user: User) => void]>([undefined, () => { }])

export const UserProvider: React.FC<{ session?: Session }> = ({ session, children }) => {
    const [user, setUser] = useState<User | undefined>(session?.name ? { name: session.name } : undefined)
    return <UserContext.Provider value={[user, setUser]}>
        {children}
    </UserContext.Provider>
}

export const useUser = () => useContext(UserContext)



export const ServerSettingsContext = React.createContext<ServerSettings | undefined>({ codiUrl: "", sfuUrl: "" })

export const ServerSettingsProvider: React.FC<{ serverSettings?: ServerSettings }> = ({ children, serverSettings }) => {
    return <ServerSettingsContext.Provider value={serverSettings}>
        {children}
    </ServerSettingsContext.Provider>
}

export const useServerSettings = () => useContext(ServerSettingsContext)



export const LocalSessionContext = React.createContext<[LocalSession, (session: LocalSession) => void]>([{ selectedCtf: null }, () => { }])

export const LocalSessionProvider: React.FC<{}> = ({ children }) => {
    const [localSession, setLocalSession] = useLocalStorage<LocalSession>("local-session", { selectedCtf: null })
    return <LocalSessionContext.Provider value={[localSession, setLocalSession]}>
        {children}
    </LocalSessionContext.Provider>
}

export const useLocalSession = () => useContext(LocalSessionContext)